import { v4 as uuidv4 } from 'uuid';
import * as types from './formActionTypes';
import * as metaTypes from '../actions/formMetaTypes';
import * as nodetypes from '../components/forms/stepTypes';
import * as stackactiontypes from '../common/stackactiontypes';
import * as logtypes from '../common/logtypes';
import * as trackingtypes from '../common/trackingeventnames';
import CustomEvent from 'custom-event';
import FormApi from '../api/formApi';
import { actions } from 'react-redux-form';
import { createAndSumbitForm } from '../utils/formPoster';
import { createBrowserHistory } from 'history';
import storageFactory from '../utils/storageFactory';
import {
  updateStepLabelVisibility,
  hydrateActionSteps,
  isActionStep,
  getControlDefForMultiPart,
} from '../utils/stepFunctions';
import objectMapper from 'object-mapper';
import {
  ltLogger,
  fireXsellEvent,
  getQueryParams,
  getParamsFromUrl,
} from '../bootstrap/index';
import { getStore } from '../store';
import { isPII } from '../utils/piiHelper';
import executeProcess from './process/process';

let history = createBrowserHistory();
let storage = storageFactory();

const replaceHistory = (results) => {
  if (results && results.stepSet && results.stepSet[0]) {
    history.replace(`${location.pathname}${location.search}${location.hash}`, {
      viewIndex: results.stepIndex,
      stepId: results.stepSet[0].id,
    });
    if (window.ltPhoenix) {
      window.ltPhoenix.firstLoad = false;
    }
  }
};

const checkForTermsAndDisclosures = (step, state) => {
  const { terms, usesnewterms } = step;
  const { hasAgreedToTCPATerms, hasAgreedToCreditTerms } = state.formMeta;

  return (
    terms === true &&
    (!usesnewterms || (hasAgreedToTCPATerms && hasAgreedToCreditTerms))
  );
};

export function getStep(formId, data, stackAction, shouldAlterState) {
  return function (dispatch, getState) {
    dispatch(
      loadingForm(
        formId,
        data.formMeta.treeAuthId,
        data.formMeta.autoSubmit,
        data.formMeta.autoSubmitStatus,
        data.formMeta.insideFlow
      )
    );
    if (
      window.ltPhoenix.handledClicks &&
      data &&
      data.formMeta &&
      data.formMeta.stepSet
    ) {
      window.ltPhoenix.handledClicks['GetStep'] = {
        stepSet: data.formMeta.stepSet,
      };
    }
    return FormApi.getStep(
      formId,
      data,
      stackAction,
      storage.getItem('lt_sessionKey')
    )
      .then((results) => {
        if (!results || results === null || results.error) {
          throw `Form Step for ${formId} could not be loaded!`;
        }
        // Step loaded, clear deadclick queue and handledClicks
        if (window.ltDeadclickQueue) {
          window.ltPhoenix.handledClicks = {};
          window.ltDeadclickQueue = [];
        }
        if (data.formMeta.autoSubmitStatus) {
          ltLogger(
            logtypes.TRACK,
            trackingtypes.FORM_AUTO_SUBMIT_START,
            {
              ...getState().formMeta,
              formUuid: results.formUuid,
              sessionKey: results.sessionKey,
              vertical: results.vertical,
              stepIndex: results.stepIndex,
            },
            undefined,
            undefined
          );
        }
        if (
          results.stepSet[0].nodetype !== nodetypes.FORM_ACTION &&
          (shouldAlterState || !stackAction)
        ) {
          switch (stackAction) {
            case stackactiontypes.PUSH:
              {
                if (window.ltPhoenix) {
                  if (window.ltPhoenix.firstLoad) {
                    replaceHistory(results);
                  } else {
                    history.push(
                      `${location.pathname}${location.search}${location.hash}`,
                      {
                        viewIndex: results.stepIndex,
                        stepId: results.stepSet[0].id,
                      }
                    );
                    window.ltPhoenix.hasPreviousStep = true;
                  }
                }
              }
              break;
            default:
              {
                replaceHistory(results);
              }
              break;
          }
        }
        results.variation = data.formMeta.vid;
        if (getState().formData.processingTextOverride) {
          dispatch(
            actions.change('formData.processingTextOverride', undefined)
          );
        }
        dispatch(loadedStepSuccess(formId, results));
      })
      .catch((error) => {
        ltLogger(logtypes.ERROR, undefined, {}, undefined, {
          error: error,
        });
        if (
          window.ltPhoenix.callbacks &&
          window.ltPhoenix.callbacks.onCriticalError
        ) {
          window.ltPhoenix.callbacks.onCriticalError();
        }
      });
  };
}

export function handleNextSteps(steps, stackAction, bypassvalidation) {
  return function (dispatch, getState) {
    if (getState().formState.formStatus === types.FORM_BUSY) return;

    //let state = getState();
    let invalidSteps = [];
    let deleteFormData = [];

    //Validate steps and stackAction
    steps.forEach((step) => {
      // PHX-6711: If no lender selected on the SLC page, do not submit the form and mark node as invalidSteps
      const hasSLCLenderList = step?.SLCLenderList?.length !== 0;
      const hasNoConsentedPartnerIds =
        getState().formMeta?.consentedToPartnerIds?.length === 0;
      if (
        step.usesnewterms &&
        step.hasSLCDisclosures &&
        hasSLCLenderList &&
        hasNoConsentedPartnerIds
      ) {
        invalidSteps.push(step.attributeid);
      }

      if (getState().forms.formData[step.attributeid]) {
        dispatch(actions.setTouched(`formData.${step.attributeid}`));
      }
      if (step.nodetype === nodetypes.FORM_SELECT) {
        if (getState().formData[step.attributeid]) {
          const stepAttribute = getState().formData[step.attributeid];
          const isDefaultValue =
            stepAttribute.trim === 'function'
              ? stepAttribute && !stepAttribute.trim().length
              : false;
          const isRequired = step.validation && step.validation.required;
          if (isDefaultValue && isRequired) {
            dispatch(
              actions.setValidity(`formData.${step.attributeid}`, {
                required: false,
              })
            );
          }
        }
      }

      const controlNode = getState().forms.formData[step.attributeid]
        ? getState().forms.formData[step.attributeid].valid !== undefined
          ? getState().forms.formData[step.attributeid]
          : getState().forms.formData[step.attributeid].$form
        : undefined;

      if (
        controlNode === undefined ||
        controlNode.valid === false ||
        controlNode.validating ||
        (step.validation &&
          step.validation.required === 'true' &&
          controlNode.pristine === true)
      )
        invalidSteps.push(step.attributeid);

      if (
        stackAction === stackactiontypes.POP &&
        controlNode &&
        controlNode.valid === false
      ) {
        dispatch(actions.change(`formData.${step.attributeid}`, undefined));
        dispatch(actions.resetValidity(`formData.${step.attributeid}`));
      }
      if (step.nodetype === nodetypes.FORM_UPLOAD) {
        dispatch(actions.change(`formData.${step.attributeid}`, undefined));
        //dispatch(actions.change(`formData.${step.attributeid}Group[]`, undefined));

        if (
          stackAction !== stackactiontypes.PUSH &&
          getState().forms.formData[`${step.attributeid}`].valid === false
        ) {
          dispatch(actions.resetValidity(`formData.${step.attributeid}`));
        } else if (
          getState().forms.formData[`${step.attributeid}`].valid === false
        ) {
          dispatch(actions.setDirty(`formData.${step.attributeid}`));
          dispatch(actions.setTouched(`formData.${step.attributeid}`));
          invalidSteps.push(`${step.attributeid}`);
        }
        deleteFormData.push(`formData.${step.attributeid}`);
      }

      if (step.hasTCPADisclosures === true && stackAction === 1) {
        dispatch(actions.change('formMeta.hasAgreedToTCPATerms', true));
      }
      if (step.hasCreditDisclosures === true && stackAction === 1) {
        dispatch(actions.change('formMeta.hasAgreedToCreditTerms', true));
      }
      if (
        (step.hasTCPADisclosures === true ||
          step.hasCreditDisclosures === true) &&
        getState().formMeta.hasAgreedToTCPATerms &&
        getState().formMeta.hasAgreedToCreditTerms
      ) {
        ltLogger(
          logtypes.TRACK,
          trackingtypes.CHECKOUT_STARTED,
          getState().formMeta
        );
      }
    });

    //Validate all step data in formdata
    const formDefData = getState().forms.formData;
    const formData = getState().formData;
    const formDefinition = getState().formDefinition.stepSet;
    let invalidFormData = [];
    Object.keys(getState().forms.formData).forEach((stepKey) => {
      if (stepKey === '$form') return;
      const formStep = formDefData[stepKey];
      const formDef = formDefinition.find((def) => def.attributeid === stepKey);

      if (
        formDef === undefined ||
        formStep.valid === false ||
        formStep.validating ||
        (formDef.validation &&
          formDef.validation.required === 'true' &&
          formStep.pristine === true) ||
        (formDef.placeholder && formData[stepKey] === formDef.placeholder)
      )
        invalidFormData.push(stepKey);
    });

    if (
      stackAction === stackactiontypes.PUSH &&
      invalidSteps.length > 0 &&
      !bypassvalidation
    ) {
      return;
    }

    const event = new CustomEvent('phoenixNavigation', {
      detail: {
        stackAction: stackAction,
        includesEmail: formDefinition.some(
          (step) => step.attributeid === 'EmailAddress'
        ),
      },
      composed: true,
      bubbles: true,
    });
    window.dispatchEvent(event);

    formDefinition.forEach((step) => {
      if (
        step.attributeid === 'PurchaseIntent' &&
        formData[step.attributeid] === 'purchase-under-contract'
      ) {
        const event = new CustomEvent('PurchaseIntentUnderContract', {
          detail: {
            stackAction: stackAction,
          },
          composed: true,
          bubbles: true,
        });
        window.dispatchEvent(event);
      } else if (step.attributeid === 'PhoneNumber') {
        const event = new CustomEvent('PhoneNumberStepCompleted', {
          detail: {
            stackAction: stackAction,
          },
          composed: true,
          bubbles: true,
        });
        window.dispatchEvent(event);
      }
    });
    dispatch(formStatusReset());

    let variation = `${window.ltPageName || 'none'} | ${
      window.ltPageTheme || 'none'
    } | ${window.ltFormTheme || 'none'}`;
    const cleanMeta = Object.assign({}, getStore().getState().formMeta);
    delete cleanMeta.stepIndex;
    delete cleanMeta.stepName;
    let cleanFormData = {};
    Object.entries(getState().formData).forEach(([key, value]) => {
      if (!steps.find((step) => step.attributeid === key)) return;
      if (
        !invalidFormData.find(
          (item) =>
            item === key && !deleteFormData.find((remove) => remove === key)
        ) ||
        (bypassvalidation && !deleteFormData.find((remove) => remove === key))
      ) {
        const curStep = steps.find((item) => item.attributeid === key);

        if (
          (bypassvalidation &&
            curStep != null &&
            curStep.placeholder &&
            curStep.placeholder !== value &&
            value != null &&
            value.length > 0) ||
          !bypassvalidation
        ) {
          cleanFormData[key] = value;
        }
      }
      const stepDef =
        formDefinition.find((def) => def.attributeid === key) ||
        getState().formDefinition.allsteps.find(
          (def) => def.attributeid === key
        );
      if (stepDef && stepDef.nodetype === nodetypes.FORM_RANGE) {
        cleanFormData[key] =
          value || stepDef.value || stepDef.rangecontrol.initialValue;
      }
    });

    let extraVehicleFormData = [
      'AutomobileLoanType',
      'AutomobileLoanSubType',
      'VehicleYear',
      'ChromeStyleId',
      'ChromeFriendlyStyleName',
      'KBBTrimId',
      'VehicleMSRP',
      'VehicleInvoicePrice',
      'VehicleWholesalePrice',
    ];

    formDefinition.forEach((step) => {
      if (
        step.attributeid === 'VehicleMake' ||
        step.attributeid === 'VehicleModel' ||
        step.attributeid === 'VehicleTrim'
      ) {
        Object.entries(getState().formData).forEach(([key, value]) => {
          //ignore entry if value is empty,null or undefined
          if (
            !value ||
            (cleanMeta['previousquestionasked'] === key &&
              cleanMeta['previousquestionsanswer'] === value)
          )
            return;
          if (step.attributeid === key) {
            cleanFormData[key] = value;
            if (getState().formData[`${key}Id`])
              cleanFormData[`${key}Id`] = getState().formData[`${key}Id`];
          }
        });
        extraVehicleFormData.forEach((vehicleFormData) => {
          if (
            !cleanFormData[vehicleFormData] &&
            getState().formData[vehicleFormData] !== undefined
          ) {
            cleanFormData[vehicleFormData] =
              getState().formData[vehicleFormData];
          }
          if (
            !cleanFormData[vehicleFormData] &&
            getState().formMeta[vehicleFormData] !== undefined
          ) {
            cleanFormData[vehicleFormData] =
              getState().formMeta[vehicleFormData];
          }
        });
      }
    });
    //Add extra props
    if (getState().formData.extra) {
      Object.keys(getState().formData.extra).forEach((extraprop) => {
        if (
          steps.find(
            (item) =>
              item.attributeid === extraprop ||
              (item.summarysettings && item.summarysettings.readonly)
          )
        )
          cleanFormData[getState().formData.extra[extraprop]] =
            getState().formData[getState().formData.extra[extraprop]];
      });
    }

    //Add extra data fields
    if (getState().formData.extraDataFields) {
      Object.keys(getState().formData.extraDataFields).forEach((extraprop) => {
        if (steps.find((item) => item.attributeid === extraprop)) {
          dispatch(actions.reset(`formData.extraDataFields.${extraprop}`));
          return;
        }
        cleanFormData[extraprop] =
          getState().formData.extraDataFields[extraprop];
      });
    }

    /**
     * PHX-5450: the following is currently an experimental method of
     * sending data from client-only after actions to the API
     * in the step call that were previously missing.
     */
    formDefinition.forEach((step) => {
      if (step.actionsafterchange && step.actionsafterchange.length > 0) {
        let clientOnlyAfterActionsData = [];
        for (let i = 0; i < step.actionsafterchange.length; i++) {
          if (step.actionsafterchange[i].runat === 'clientonly-experimental') {
            for (
              let k = 0;
              k < step.actionsafterchange[i].saveMap.length;
              k++
            ) {
              // add saveMap fields & values
              clientOnlyAfterActionsData.push(
                step.actionsafterchange[i].saveMap[k].statename
              );
            }
          }
        }
        if (clientOnlyAfterActionsData.length > 0) {
          clientOnlyAfterActionsData.forEach((extraAfterAction) => {
            let currentFieldName = extraAfterAction.slice(
              extraAfterAction.indexOf('.') + 1
            );
            // route depending on value of after action saveMap ('formData' or 'formMeta')
            switch (extraAfterAction.slice(0, extraAfterAction.indexOf('.'))) {
              case 'formData':
                if (
                  !cleanFormData[currentFieldName] &&
                  getState().formData[currentFieldName] !== undefined
                ) {
                  cleanFormData[currentFieldName] =
                    getState().formData[currentFieldName];
                }
                break;
              case 'formMeta':
                if (
                  !cleanMeta[currentFieldName] &&
                  getState().formMeta[currentFieldName] !== undefined
                ) {
                  cleanMeta[currentFieldName] =
                    getState().formMeta[currentFieldName];
                }
                break;
              default:
                break;
            }
          });
        }
      }
    });

    dispatch(updatePreviousQuestionMeta(steps, cleanFormData));
    processTrackingEvents(
      steps,
      getState(),
      cleanFormData,
      bypassvalidation,
      stackAction
    );
    if (
      steps.some((step) => step.terms === true) &&
      stackAction === stackactiontypes.PUSH &&
      window.ltPhoenix.delayStepSubmit === true
    ) {
      const cleanData = updateCurrentQuestionFormMeta(
        steps,
        cleanFormData,
        stackAction,
        variation,
        cleanMeta
      );
      dispatch(
        loadingForm(
          getState().formDefinition.formId,
          cleanData.formMeta.treeAuthId,
          cleanData.formMeta.autoSubmit,
          cleanData.formMeta.autoSubmitStatus,
          cleanData.formMeta.insideFlow
        )
      );
      setTimeout(() => {
        dispatch(
          getStep(
            getState().formDefinition.formId,
            cleanData,
            stackAction,
            true
          )
        );
      }, 1250);
    } else {
      dispatch(
        getStep(
          getState().formDefinition.formId,
          updateCurrentQuestionFormMeta(
            steps,
            cleanFormData,
            stackAction,
            variation,
            cleanMeta
          ),
          stackAction,
          true
        )
      );
    }
  };
}

export function changeMeta(item) {
  return { type: metaTypes.META_CHANGE, formMeta: item.formMeta };
}

export function changeFormStepProps(item) {
  return { type: types.FORM_STEP_UPDATE, item };
}

export function changeFormDefinition(item) {
  return { type: types.FORM_DEFINITION_UPDATE, formDefinition: item };
}

export function changeFormState(item) {
  return { type: types.FORM_STATE_UPDATE, formState: item };
}

export function setFormWarning(warnings) {
  return { type: types.FORM_SET_WARNING, warnings };
}

export function formStatusReset() {
  return { type: types.FORM_DEFAULT };
}

export function setFormBusy() {
  return { type: types.FORM_BUSY };
}

export function formStatusShowingExplainText() {
  return { type: types.FORM_SHOW_EXPLAIN_SIDEBAR };
}

export function changeFormData(key, value) {
  return function (dispatch) {
    dispatch(actions.change(`formData.${key}`, value));
  };
}

export function clearFormData() {
  return function (dispatch) {
    dispatch(actions.change('formData', {}));
  };
}

function loadingForm(
  formId,
  treeAuthId = '',
  autoSubmit = false,
  autoSubmitStatus = null,
  insideFlow = false
) {
  return {
    type: types.FORM_LOADING,
    formId,
    autoSubmit: autoSubmit,
    autoSubmitStatus: autoSubmitStatus,
    treeAuthId,
    insideFlow,
  };
}

function autoSubmitFail() {
  return { type: types.FORM_AUTO_SUBMIT_FAIL, status: false };
}

export function addChatBubble({ chatSource = '', chatText = '' } = {}) {
  return {
    type: 'ADD_CHAT_BUBBLE',
    chatBubble: {
      id: uuidv4(),
      chatSource,
      chatText,
    },
  };
}

export function resetAutoSubmitStatus() {
  return changeMeta({ formMeta: { autoSubmitStatus: null } });
}

/**
 *
 * @param {string} current Original URL that is being redirected from
 * @param {string} target Target URL that is being redirected to
 * @param {Object.<string>} params Any additional parameters to inject
 *
 * TODO: Refactor to use the built in URL API which will require the use
 * of a shim for IE11 support.  See PHX-2849 for an example code snippet.
 */
function buildRedirectUrl(current, target, params) {
  let combinedParams = {
    ...getParamsFromUrl(current),
    ...getParamsFromUrl(target),
    ...params,
  };
  let [href] = target.split('?');
  if (Object.keys(combinedParams).length > 0) {
    href +=
      '?' +
      Object.keys(combinedParams)
        .map((param) => `${param}=${combinedParams[param]}`)
        .join('&');
  }
  return href;
}

function redirectToUrl(redirectUrl, parentQFormUid = '') {
  let additionalParams = {};
  if (parentQFormUid.length > 0) {
    additionalParams.parent_qformuid = parentQFormUid;
  }
  location.href = buildRedirectUrl(
    window.location.href,
    redirectUrl,
    additionalParams
  );
}

function handleAnalyticsFields(formMeta, analyticsFields) {
  if (analyticsFields.equifaxMobileNetworkFoundTrack !== undefined) {
    const trackName = trackingtypes.EQUIFAX_MOBILE_STATUS;
    ltLogger(logtypes.TRACK, trackName, {
      ...formMeta,
      equifaxUserOnMobile: analyticsFields.equifaxMobileNetworkFoundTrack,
    });
  }
  if (analyticsFields.equifaxIdentityVerifiedTrack === true) {
    const equifaxFieldsReturned = Object.keys(
      analyticsFields.originalEquifaxPayload
    );
    const trackName = trackingtypes.EQUIFAX_FIELDS_RETURNED;
    ltLogger(logtypes.TRACK, trackName, { ...formMeta, equifaxFieldsReturned });
  }
  if (analyticsFields.equifaxIdentityModificationsTrack === true) {
    const trackName = trackingtypes.EQUIFAX_FIELDS_MODIFIED;
    ltLogger(logtypes.TRACK, trackName, {
      ...formMeta,
      equifaxFieldsModified: analyticsFields.equifaxFieldsModified,
    });
  }
}
function updateAutomobileLoanType(processDefinition, getState, dispatch) {
  const { formMeta } = getState();
  let loanSubType = formMeta.AutomobileLoanSubType;
  if (
    loanSubType &&
    processDefinition.requestMap.some(
      (e) => e.attributename === 'formData.AutomobileLoanType'
    )
  ) {
    let loanType = undefined;
    switch (loanSubType.toUpperCase()) {
      case 'NEWCARPURCHASE':
        loanType = 'Purchase';
        break;
      case 'REFINANCE':
        loanType = 'Refinance';
        break;
      case 'BUYOUTLEASE':
        loanType = 'BUYOUTLEASE';
        break;
    }
    if (loanType)
      dispatch(actions.change('formData.AutomobileLoanType', loanType));
  }
}
export function processAndChangeStepProperty(
  processDefinition,
  step,
  selectedEnum
) {
  return async function (dispatch, getState) {
    dispatch(
      changeFormStepProps({
        targetProp: processDefinition.targetProperty,
        targetValue: processDefinition.initialValue,
        targetStepId: processDefinition.targetStep,
      })
    );
    updateAutomobileLoanType(processDefinition, getState, dispatch);
    const { formData } = getState();
    if (
      processDefinition.requestMap.some(
        (param) => !objectMapper.getKeyValue({ formData }, param.attributename)
      )
    )
      return;

    let data = await FormApi.process({
      formData,
      processDefinition,
    });
    if (
      (data === undefined || data.length === 0) &&
      step &&
      step.attributeid === 'RadiusInMiles'
    ) {
      let { enumeration, attributeid } = step;
      let currentIndex = enumeration.findIndex(
        (item) =>
          item.value ===
          (selectedEnum === undefined
            ? getState().formData[attributeid]
            : selectedEnum.value.toString())
      );
      currentIndex++;
      for (
        currentIndex;
        currentIndex < enumeration.length && data.length === 0;
        currentIndex++
      ) {
        dispatch(actions.setTouched(`formData.${attributeid}`));
        dispatch(
          actions.change(
            `formData.${attributeid}`,
            enumeration[currentIndex].value
          )
        );
        let { formData } = getState();
        data = await FormApi.process({
          formData,
          processDefinition,
        });
      }
    }

    if (!data) return;

    const displaymap =
      processDefinition.displaymap && JSON.parse(processDefinition.displaymap);

    const displayMapTransform =
      processDefinition.displayMapTransform &&
      processDefinition.displayMapTransform.length > 0
        ? processDefinition.displayMapTransform
        : [];

    displayMapTransform.forEach((item) => {
      displaymap[item.key].transform = new Function(...item.transformFunc);
    });

    // PHX-5621: VehicleYear requires map to be label/value
    if (processDefinition.name === 'Enrich Auto Years (After Action)') {
      data = data.map((item) =>
        typeof item === 'object' && item !== null
          ? objectMapper(item, displaymap)
          : { label: `${item}`, value: `${item}` }
      );
    }

    const targetValue =
      displaymap && Object.keys(displaymap).length !== 0
        ? typeof data === 'object'
          ? Array.isArray(data)
            ? data.map((item) => objectMapper(item, displaymap))
            : objectMapper(data, displaymap)
          : data
        : data;

    if (
      processDefinition.targetProperty === 'enumeration' &&
      !targetValue.some(
        ({ value }) => value === formData[processDefinition.targetStep]
      )
    ) {
      dispatch(actions.reset(`formData.${processDefinition.targetStep}`));
    }

    dispatch(
      changeFormStepProps({
        targetProp: processDefinition.targetProperty,
        targetValue,
        targetStepId: processDefinition.targetStep,
      })
    );
  };
}
function updateRelevantServerData(data, dispatch) {
  Object.entries(data).forEach(([key, value]) => {
    switch (key) {
      case 'formMeta':
        dispatch(changeMeta(objectMapper.setKeyValue({}, key, value)));
        break;
      case 'formData':
        Object.entries(data[key]).forEach(([key, value]) => {
          dispatch(actions.change(`formData.${key}`, value));
        });
        break;
    }
  });
}
function loadedStepSuccess(formId, payload) {
  return async function (dispatch, getState) {
    if (
      payload &&
      payload.relevantServerData &&
      payload.relevantServerData.formMeta &&
      payload.relevantServerData.formMeta.lastStepDisposition
    ) {
      const { formMeta } = getStore().getState();
      const lastStepDisposition =
        payload.relevantServerData.formMeta.lastStepDisposition;
      delete payload.relevantServerData.formMeta.lastStepDisposition;
      if (
        lastStepDisposition === 'FIRST' ||
        lastStepDisposition === 'RESTART'
      ) {
        const event = new CustomEvent('ltPhoenixSLCFieldsCleared', {
          detail: {
            fieldsCleared: [
              'formMeta.SLCLenderList',
              'formMeta.ConsentMatchState',
              'formMeta.JornayaScriptLoaded',
              'formMeta.LeadIdDataCaptured',
              'formMeta.JornayaTokenCreated',
              'formMeta.leadtcpatoken',
              'formMeta.APScriptLoaded',
              'formMeta.APTokenCreated',
              'formMeta.APTokenIsClaimed',
              'formMeta.APTokenClaimAttempts',
              'formMeta.leadtcpatokenAP',
              'formMeta.consentedToPartnerIds',
              'formMeta.hasAgreedToTCPATerms',
              'formMeta.hasAgreedToCreditTerms',
            ],
          },
          composed: true,
          bubbles: true,
        });
        const clearedSLCData = {
          SLCLenderList: undefined,
          ConsentMatchState: undefined,
          JornayaScriptLoaded: undefined,
          LeadIdDataCaptured: undefined,
          JornayaTokenCreated: undefined,
          leadtcpatoken: undefined,
          APScriptLoaded: undefined,
          APTokenCreated: undefined,
          APTokenIsClaimed: undefined,
          APTokenClaimAttempts: undefined,
          leadtcpatokenAP: undefined,
          consentedToPartnerIds: undefined,
          hasAgreedToTCPATerms: undefined,
          hasAgreedToCreditTerms: undefined,
        };
        if (payload.relevantServerData.formMeta.restartedExpiredSession) {
          const cleanFormMeta = {
            autoSubmit: false,
            autoSubmitStatus: false,
            formId: '',
            insideFlow: false,
            product: '',
            treeAuthId: '',
          };
          getStore().dispatch(changeMeta({ formMeta: cleanFormMeta }));
          getStore().dispatch(clearFormData());
        } else {
          getStore().dispatch(
            changeMeta({ formMeta: { ...formMeta, ...clearedSLCData } })
          );
        }
        window.dispatchEvent(event);
      }
    }
    if (payload.analyticsFields) {
      handleAnalyticsFields(getState().formMeta, payload.analyticsFields);
    }
    if (payload.stepSet[0].redirectUrl !== undefined) {
      if (payload.stepSet[0].submitForm) {
        const { redirectUrl, formMethod, dontpostdata, loaderMessage } =
          payload.stepSet[0];
        dispatch(
          submitFormData(
            payload.FieldValues,
            redirectUrl,
            formMethod || 'post',
            !dontpostdata,
            loaderMessage
          )
        );
        return;
      } else {
        if (payload.stepSet[0].isXSell) {
          redirectToUrl(payload.stepSet[0].redirectUrl, payload.formUuid);
        } else {
          redirectToUrl(payload.stepSet[0].redirectUrl);
        }
      }
      return;
    }
    if (payload.relevantServerData)
      updateRelevantServerData(payload.relevantServerData, dispatch);

    for (const step of payload.stepSet) {
      if (
        (step.nodetype == nodetypes.FORM_RADIO ||
          step.nodetype === nodetypes.FORM_SELECT ||
          step.nodetype == nodetypes.FORM_COMBOBOX) &&
        getState().formData[step.attributeid] === undefined &&
        !isActionStep(step)
      ) {
        if (step.enumdefault !== null || step.value) {
          dispatch(
            actions.change(
              `formData.${step.attributeid}`,
              step.value || step.enumdefault
            )
          );
          //dispatch(actions.setValidity(`formData.${step.attributeid}`, {required: true}));
          if (step.value)
            dispatch(actions.setSubmitted(`formData.${step.attributeid}`));
        } else {
          if (
            !(step.placeholder && step.placeholder.length > 0) &&
            step.enumeration.length > 0 &&
            step.nodetype === nodetypes.FORM_SELECT
          ) {
            dispatch(
              actions.change(
                `formData.${step.attributeid}`,
                step.enumeration
                  .slice(0)
                  .filter((x) => x.displayOrder > -1)
                  .sort((a, b) => Math.abs(a.displayOrder - b.displayOrder))[0]
                  .value
              )
            );
          }
        }
      }
      if (
        step.actionbeforerender &&
        (step.nodetype === nodetypes.FORM_COMBOBOX ||
          step.nodetype === nodetypes.FORM_SELECT ||
          step.nodetype === nodetypes.FORM_RADIO)
      ) {
        const response =
          (await FormApi.process({
            processDefinition: step.actionbeforerender,
            formData: getState().formData,
            formMeta: getState().formMeta,
          })) || [];
        const data =
          response.statusCode === 400 ||
          response.statusCode === 403 ||
          response.statusCode === 404 ||
          response === []
            ? []
            : response;
        const displayMapTransform =
          step.actionbeforerender.displayMapTransform &&
          step.actionbeforerender.displayMapTransform.length > 0
            ? step.actionbeforerender.displayMapTransform
            : [];
        const abrDisplaymap = JSON.parse(step.actionbeforerender.displaymap);
        displayMapTransform.forEach((item) => {
          abrDisplaymap[item.key].transform = new Function(
            ...item.transformFunc
          );
        });
        step[step.actionbeforerender.targetProperty] = data.map((item) =>
          typeof item === 'object' && item !== null
            ? objectMapper(item, abrDisplaymap)
            : { label: `${item}`, value: `${item}` }
        );
      } else if (step.nodetype == nodetypes.FORM_COMBOBOX) {
        let enumActionAfter = step.actionsafterchange
          ? step.actionsafterchange.find(
              (item) => item.targetProperty === 'enumeration'
            )
          : {};
        let displaymap = enumActionAfter ? enumActionAfter.displaymap : null;
        step.enumeration = step.enumeration
          ? transformFormStateVar(
              `${step.attributeid}.enumeration`,
              step.enumeration,
              displaymap
            )
          : getState().formState.formStep &&
            getState().formState.formStep[step.attributeid]
          ? getState().formState.formStep[step.attributeid].enumeration
          : transformFormStateVar(
              `${step.attributeid}.enumeration`,
              [step.value],
              displaymap
            );
        if (step.value)
          dispatch(actions.change(`formData.${step.attributeid}`, step.value));
      }
      if (step.actionsafterchange && getState().formData[step.attributeid]) {
        step.actionsafterchange
          .filter((action) => action.changeStepProperty)
          .forEach((action) =>
            dispatch(processAndChangeStepProperty(action, step, undefined))
          );
      }
      if (
        step.nodetype === nodetypes.FORM_RANGE &&
        step.rangecontrol.initialValue != undefined
      ) {
        let valueForSlider = step.value || step.rangecontrol.initialValue;
        if (
          valueForSlider &&
          (Number(valueForSlider) < Number(step.rangecontrol.lowerbound) ||
            Number(valueForSlider) > Number(step.rangecontrol.upperbound))
        ) {
          valueForSlider = step.rangecontrol.lowerbound;
        } else if (
          step.rangecontrol.slidertype === 'advanced' &&
          step.rangecontrol.rangeSteps
        ) {
          for (let curRangeStep of step.rangecontrol.rangeSteps) {
            if (
              Number(valueForSlider) >= Number(curRangeStep.lowrange) &&
              Number(valueForSlider) <= Number(curRangeStep.highrange)
            ) {
              valueForSlider = curRangeStep.targetvalue;
            }
          }
        }
        dispatch(
          actions.change(`formData.${step.attributeid}`, valueForSlider)
        );
        step.defaulthelp = step.help;
      }
      if (step.nodetype === nodetypes.FORM_MULTIPART) {
        let definition = getControlDefForMultiPart(
          step.formatter,
          step.multipartcontrol
        );
        dispatch(
          changeFormDefinition(
            objectMapper.setKeyValue(
              {},
              `formData.${step.attributeid}.multipartcontrol`,
              definition
            ).formDefinition
          )
        );
        dispatch(actions.change(`formData.${step.attributeid}`, step.value));
      }
      if (step.nodetype === nodetypes.FORM_DATE_SCROLL) {
        dispatch(actions.change(`formData.${step.attributeid}`, step.value));
      }
      if (step.nodetype === nodetypes.FORM_TEXT && step.value) {
        dispatch(actions.change(`formData.${step.attributeid}`, step.value));
      }
      if (step.nodetype === nodetypes.FORM_UPLOAD) {
        if (step.value) {
          //clean out failed uploads
          for (let [key, value] of Object.entries(step.value)) {
            let parsed = value;
            if (parsed.failed) delete step.value[key];
          }
          dispatch(actions.change(`formData.${step.attributeid}`, step.value));
        }
      }
      if (step.nodetype === nodetypes.FORM_CHECKBOXES && step.value) {
        dispatch(actions.change(`formData.${step.attributeid}[]`, step.value));
      }

      if (
        step.nodetype === nodetypes.FORM_TOGGLE &&
        getState().formData[step.attributeid] == null
      ) {
        const toggleEnumDefaultValue =
          step.enumdefault === 'false' ? false : Boolean(step.enumdefault);

        dispatch(
          actions.change(`formData.${step.attributeid}`, toggleEnumDefaultValue)
        );
      }

      if (
        step.nodetype === nodetypes.FORM_VEHICLE_MAKE ||
        step.nodetype === nodetypes.FORM_VEHICLE_TRIM ||
        step.nodetype === nodetypes.FORM_VEHICLE_MODEL
      ) {
        dispatch(actions.change(`formData.${step.attributeid}`, step.value));
      }

      if (step.actionsafterchange && step.actionsafterchange.length > 0) {
        dispatch(actions.setSubmitted(`formData.${step.attributeid}`, false));
      }
      if (
        getState().formData[step.attributeid] &&
        getState().formDefinition.allsteps.find(
          (e) => e.attributeid === step.attributeid
        ) &&
        getState().formMeta.stepIndex < payload.stepIndex
      )
        dispatch(actions.setUntouched(`formData.${step.attributeid}`));
    }
    payload.stepSet = hydrateActionSteps(
      payload.stepSet,
      getState().formDefinition.allsteps
    );

    //determine show next label
    updateStepLabelVisibility(payload.stepSet);

    //write sessionkey to local storage
    storage.setItem('lt_sessionKey', payload.sessionKey);

    const ltPhoenixStepLoadedEvent = new CustomEvent('ltPhoenixStepLoaded', {
      detail: {
        attributeid: payload.stepSet[0].attributeid,
        stepName: payload.stepSet[0].name,
        product: payload.product,
        stepIndex: payload.stepIndex,
      },
      composed: true,
      bubbles: true,
    });

    const defaultComponents = {
      header: null,
      footer: null,
    };
    dispatch(
      addChatBubble({ chatSource: 'bot', chatText: payload.stepSet[0].label })
    );
    dispatch({
      type: types.STEP_LOADED,
      formUuid: payload.formUuid,
      product: payload.product,
      vertical: payload.vertical || '',
      stepSet: payload.stepSet,
      stepIndex: payload.stepIndex,
      stepName: payload.stepSet[0].name,
      formId: formId,
      allsteps: payload.stepSet,
      utoken: payload.utoken,
      components: payload.components || defaultComponents,
      displayLanguage: payload.displayLanguage,
      autoSubmitStatus: !!payload.autoSubmitStatus,
      autoSubmitMissingProperties: payload.autoSubmitMissingProperties,
      formScripts: payload.formScripts,
    });
    if (getQueryParams('parent_qformuid'))
      dispatch(
        changeMeta({
          formMeta: {
            parentQFormUid: getQueryParams('parent_qformuid'),
            vid: payload.variation,
            stepSet: payload.stepSet[0].id,
          },
        })
      );
    else
      dispatch(
        changeMeta({
          formMeta: { vid: payload.variation, stepSet: payload.stepSet[0].id },
        })
      );

    let submittedProps = {};
    Object.keys(getState().formData)
      .filter(
        (item) =>
          (getState().forms.formData[item] &&
            getState().forms.formData[item].submitted) ||
          (getState().forms.formData[item] &&
            getState().forms.formData[item].$form &&
            getState().forms.formData[item].$form.submitted)
      )
      .forEach(function (key) {
        let augmentedSliderStepValues = getState().formState.augmentedValues;
        submittedProps[key] = augmentedSliderStepValues.find(
          (raw) => raw.attributeid === key
        )
          ? augmentedSliderStepValues.find((raw) => raw.attributeid === key)
              .value
          : getState().formData[key];
        const ctaProp = getState().formDefinition.allsteps.find(
          (item) => item.attributeid === key
        );
        if (ctaProp && ctaProp.enumeration && ctaProp.enumeration.length > 0) {
          let dropdownValue = getState().formData[key];
          if (!isNaN(parseInt(dropdownValue))) return;
          let pair = '';
          if (Array.isArray(dropdownValue)) {
            //^^: Added to Enum values in builder to support multiple items having same value. Slice them before submit
            let selectedValues = [];
            dropdownValue.forEach((value) => {
              let selectedOption = ctaProp.enumeration.find(
                (prop) => prop.value === value
              );
              if (selectedOption && selectedOption.label) {
                let selectedVal = value.includes('^^')
                  ? value.substr(0, value.indexOf('^^'))
                  : value;
                selectedValues.push(selectedVal);
                pair = `${selectedVal}|${selectedOption.label}, ${pair}`;
              }
              submittedProps[key] = selectedValues;
            });
            if (pair) {
              pair = pair.slice(0, -2);
            }
          } else {
            let selectedOption = ctaProp.enumeration.find(
              (prop) => prop.value === dropdownValue
            );
            if (selectedOption && selectedOption.label) {
              pair = `${dropdownValue}|${selectedOption.label}`;
              let selectedVal = dropdownValue.includes('^^')
                ? dropdownValue.substr(0, dropdownValue.indexOf('^^'))
                : dropdownValue;
              submittedProps[key] = selectedVal;
              pair = `${selectedVal}|${selectedOption.label}`;
            }
          }
          if (pair) {
            submittedProps[`${key}_ctatext`] = pair;
          }
        }
      });

    if (payload.stepSet[0].interstitial && payload.stepSet[0].isXSell) {
      let { stepSet, product, formUuid: qformuid } = payload;
      let { name, enumeration } = stepSet[0];
      let banners = enumeration.map(({ value, displayOrder }) => {
        return {
          banner_unique_id: `${formId}_${name}`,
          banner_type: 'xsell',
          banner_source: 'phoenix',
          banner_source_type: 'interstitial',
          name: value,
          vertical: '',
          section_rank: displayOrder,
          section: '',
          global_rank: displayOrder,
        };
      });
      fireXsellEvent({
        product,
        qformuid,
        name: trackingtypes.XSELL_EVENT_NAME_DISPLAYED,
        banners,
      });
    }
    window.dispatchEvent(ltPhoenixStepLoadedEvent);
    //4427: Display Fail Message
    if (getState().formMeta.autoSubmit && payload.autoSubmitStatus === false) {
      dispatch(autoSubmitFail());
      ltLogger(logtypes.TRACK, trackingtypes.FORM_AUTO_SUBMIT_FAIL, {
        ...getState().formMeta,
        ...payload.missingProperties,
      });
    }
    ltLogger(
      logtypes.PAGE,
      trackingtypes.FORM_STEP,
      { ...getState().formMeta, ...submittedProps },
      payload.stepSet
    );
  };
}
//loadedStepSuccess end

function processTrackingEvents(
  steps,
  state,
  cleanFormData,
  bypassvalidation,
  stackAction
) {
  let submittedProps = {};
  const store = getStore();

  Object.keys(state.formData)
    .filter(
      (item) =>
        state.forms.formData[item] &&
        (state.forms.formData[item].submitted ||
          (state.forms.formData[item].$form &&
            state.forms.formData[item].$form.submitted))
    )
    .forEach(function (key) {
      submittedProps[key] = cleanFormData[key]
        ? cleanFormData[key]
        : state.formData[key];
    });

  if (
    steps[0] &&
    stackAction === stackactiontypes.PUSH &&
    checkForTermsAndDisclosures(steps[0], state)
  ) {
    window.dispatchEvent(new CustomEvent('ltPhoenixAgreedTerms'), {
      composed: true,
      bubbles: true,
    });
    ltLogger(logtypes.TRACK, trackingtypes.AGREED_TERMS, {
      ...state.formMeta,
      ...submittedProps,
    });
    if (
      (state.formData.Selectconciergeorexchange !== 'concierge' ||
        state.formData.Selectconciergeorexchange === undefined) &&
      state.formMeta.insideFlow &&
      state.formMeta.product === 'business'
    ) {
      ltLogger(logtypes.TRACK, trackingtypes.FORM_CONVERSION, {
        ...state.formMeta,
        ...submittedProps,
      });
    }
  }

  if (
    state.forms.formData.FCSOptIn !== undefined &&
    state.formData.FCSOptIn !== undefined
  ) {
    let termsSigned = state.formData.FCSOptIn ? true : false;
    store.dispatch(actions.change('formData.termsSigned', termsSigned));
  }

  if (
    state.forms.formData.FCSOptIn !== undefined &&
    state.formData.FCSOptIn !== undefined
  ) {
    const trackName = state.formData.FCSOptIn
      ? trackingtypes.OOW_OPTIN_COMPLETED
      : trackingtypes.OOW_OPTOUT_COMPLETED;
    ltLogger(logtypes.TRACK, trackName, {
      ...state.formMeta,
      ...submittedProps,
    });
  }

  if (state.formData.MaskPhoneNumberIndicator !== undefined) {
    const trackName = state.formData.MaskPhoneNumberIndicator
      ? trackingtypes.PHONE_NUMBER_MASK_OPTIN
      : trackingtypes.PHONE_NUMBER_MASK_OPTOUT;
    ltLogger(logtypes.TRACK, trackName, {
      ...state.formMeta,
      ...submittedProps,
    });
  }

  if (steps[0] && steps[0].interstitial && steps[0].isXSell) {
    let {
      formMeta: { product, formUuid: qformuid },
      formData,
      formDefinition: { formId, stepSet },
    } = state;
    let { enumeration, name } = stepSet[0];
    let { attributeid } = steps[0];
    let value = formData[attributeid];
    let clickedOption = enumeration.find((option) => option.value === value);
    if (clickedOption) {
      let { displayOrder } = clickedOption;
      fireXsellEvent({
        product,
        qformuid,
        name: trackingtypes.XSELL_EVENT_NAME_CLICKED,
        banners: [
          {
            banner_unique_id: `${formId}_${name}`,
            banner_type: 'xsell',
            banner_source: 'phoenix',
            banner_source_type: 'interstitial',
            name: value,
            vertical: '',
            section_rank: displayOrder,
            section: '',
            global_rank: displayOrder,
          },
        ],
      });
    }
  }

  if (steps[0] && steps[0].interstitial) {
    ltLogger(logtypes.TRACK, trackingtypes.PROMOTION_CLICKED, {
      ...state.formMeta,
      ...{ promotion_selected: state.formData[steps[0].attributeid] },
    });
  }

  if (state.formData.Selectconciergeorexchange === 'concierge') {
    ltLogger(logtypes.TRACK, trackingtypes.FORM_CONVERSION, {
      ...state.formMeta,
      ...submittedProps,
    });
  }

  if (bypassvalidation) {
    ltLogger(logtypes.TRACK, trackingtypes.FORM_AUTOADVANCE, {
      ...state.formMeta,
      ...submittedProps,
    });
  }

  if (steps.some((step) => step.hasSLCDisclosures === true)) {
    window.dispatchEvent(new CustomEvent('ltPhoenixLenderConsent'), {
      composed: true,
      bubbles: true,
    });
    ltLogger(logtypes.TRACK, trackingtypes.LENDER_CONSENT, {
      ...state.formMeta,
      ...submittedProps,
    });
  }
}

function submitFormData(
  payload,
  resultantUrl,
  formMethod,
  doPost,
  loadingMessage
) {
  return function (dispatch, getState) {
    dispatch(
      submittingForm(loadingMessage, getState().formMeta.autoSubmitStatus)
    );

    const { config, formMeta } = getState();
    ltLogger(logtypes.TRACK, trackingtypes.FORM_POSTED, {
      ...formMeta,
      'ssn9-exists': payload['ssn9-exists'],
    });

    if (window.ltPhoenix.callbacks && window.ltPhoenix.callbacks.onSubmit) {
      window.ltPhoenix.callbacks.onSubmit(payload);
    }
    window.ltPhoenixActions &&
      window.ltPhoenixActions.stopAPRecording('FormSubmitEvent');

    if (
      formMeta.ActiveProspectTokenClaimAttempts > 0 &&
      formMeta.ActiveProspectTokenIsClaimed === false
    ) {
      ltLogger(logtypes.ERROR, undefined, {}, undefined, {
        error: new Error(
          'Form submitted with unclaimed an Active Prospect token'
        ),
        metaData: 'Form submitted with unclaimed an Active Prospect token',
      });
    }

    if (!window.preventRedirect) {
      const dispositionPath = resultantUrl || config.apiConfig.expressServerUrl;
      if (doPost) {
        const { guid, tid, vid, ...rest } = payload;
        const params = {};
        if (guid) params['guid'] = guid;
        if (tid) params['tid'] = tid;
        if (vid) params['vid'] = vid;
        const target = buildRedirectUrl(
          window.location.href,
          dispositionPath,
          params
        );

        let data = payload;
        // form submission for GET method removes url params, so including in data
        if (formMethod === 'get') {
          data = { ...rest, ...getParamsFromUrl(target) };
        }

        if (formMeta.formConversionEventFired !== true) {
          ltLogger(logtypes.TRACK, trackingtypes.FORM_CONVERSION, {
            eventName: trackingtypes.FORM_CONVERSION,
            ...formMeta,
            ...data,
          });
        }

        // PHX-5872: Tech Debt: This should be getting reset on submit, but is still there if you  go back to the form afterwards.
        // Will follow up to have it reset with everything else.
        const store = getStore();
        store.dispatch(
          actions.change('formMeta.formConversionEventFired', false)
        );

        createAndSumbitForm({ data, target, method: formMethod });
      } else {
        redirectToUrl(dispositionPath, payload.formUuid);
      }
    } else {
      const redirectPreventedEvent = new CustomEvent(
        'ltPhoenixRedirectPrevented',
        {
          detail: {
            detail: resultantUrl,
          },
          bubbles: true,
          composed: true,
        }
      );
      window.dispatchEvent(redirectPreventedEvent);
    }
  };
}

export function runActionsAfterStep(
  displayedSteps,
  step,
  formatter,
  skipValidation
) {
  return function (dispatch, getState) {
    //Refactor this to be like the process node aggregators.  Will be faster processing
    let processActionsAfterStep = (params) => {
      if (
        params.processDefinition.targetStep &&
        params.processDefinition.targetProperty !== 'enumeration'
      )
        dispatch(
          changeFormStepProps({
            targetProp: params.processDefinition.targetProperty,
            targetValue: '',
            targetStepId: params.processDefinition.targetStep,
          })
        );

      //Only run if valid entry
      if (
        !params.processDefinition.autosuggest &&
        (getState().forms.formData[step.attributeid] === undefined ||
          (!skipValidation &&
            getState().forms.formData[step.attributeid].valid === false) ||
          getState().forms.formData[step.attributeid].validating ||
          (step.validation &&
            step.validation.required === 'true' &&
            getState().forms.formData[step.attributeid].pristine === true) ||
          (step.placeholder &&
            getState().formData[step.attributeid] === step.placeholder))
      )
        return Promise.resolve();

      dispatch(actions.setValidating(`formData.${step.attributeid}`));
      let processResult = null;
      if (params.processDefinition.type === 'function') {
        let requestData = {};
        params.processDefinition.requestMap.forEach((param) => {
          requestData[param.paramname] = objectMapper.getKeyValue(
            params,
            param.attributename
          );
        });
        requestData.authType = params.processDefinition.authType;
        processResult = executeProcess(
          params.processDefinition.clientProcessId,
          requestData,
          (meta = {}) =>
            ltLogger(logtypes.TRACK, trackingtypes.STREET_SUGGESTIONS_TIMEOUT, {
              ...getState().formMeta,
              ...meta,
            })
        );
      } else {
        processResult = FormApi.process(params);
      }
      return processResult
        .then((payload) => {
          if (
            !payload &&
            params.processDefinition.targetStep &&
            params.processDefinition.targetProperty !== 'enumeration'
          ) {
            //reset properties
            dispatch(
              changeFormStepProps({
                targetProp: params.processDefinition.targetProperty,
                targetValue: '',
                targetStepId: params.processDefinition.targetStep,
              })
            );
            dispatch(
              actions.setValidating(`formData.${step.attributeid}`, false)
            );
            return;
          }
          if (
            params.processDefinition.targetStep === 'VehicleDealerList' &&
            (payload === undefined || payload.length === 0)
          ) {
            let radiusInMiles = params.formDefinition.stepSet.filter(
              (item) => item.attributeid === 'RadiusInMiles'
            );
            if (radiusInMiles.length) {
              let { enumeration, attributeid } = radiusInMiles[0];
              let currentIndex = enumeration.findIndex(
                (item) =>
                  item.value ===
                  (getState().formData[attributeid] === undefined
                    ? radiusInMiles.value
                    : getState().formData[attributeid].toString())
              );
              currentIndex++;
              if (enumeration.length !== currentIndex) {
                dispatch(actions.setTouched(`formData.${attributeid}`));
                dispatch(
                  actions.change(
                    `formData.${attributeid}`,
                    enumeration[currentIndex].value
                  )
                );
                dispatch(
                  actions.setValidating(`formData.${step.attributeid}`, false)
                );
                processActionsAfterStep({
                  ...params,
                  formData: getState().formData,
                });
                return;
              }
            }
          }
          if (
            payload &&
            params.processDefinition.targetStep &&
            params.processDefinition.targetProperty === 'enumeration'
          ) {
            const displaymap =
              params.processDefinition.displaymap &&
              JSON.parse(params.processDefinition.displaymap);
            const displayMapTransform =
              params.processDefinition.displayMapTransform &&
              params.processDefinition.displayMapTransform.length > 0
                ? params.processDefinition.displayMapTransform
                : [];
            displayMapTransform.forEach((item) => {
              displaymap[item.key].transform = new Function(
                ...item.transformFunc
              );
            });
            dispatch(
              changeFormStepProps({
                targetProp: params.processDefinition.targetProperty,
                targetValue: displaymap
                  ? typeof payload === 'object'
                    ? Array.isArray(payload)
                      ? payload.map((item) => objectMapper(item, displaymap))
                      : objectMapper(payload, displaymap)
                    : payload
                  : payload,
                targetStepId: params.processDefinition.targetStep,
              })
            );
          }

          if (!payload) {
            dispatch(
              actions.setValidating(`formData.${step.attributeid}`, false)
            );
            return;
          }

          if ('navigateToNextStep' in payload) {
            dispatch(
              actions.setValidating(`formData.${step.attributeid}`, false)
            );
            if (payload.navigateToNextStep === true) {
              //Reset all fields on current step and continue
              displayedSteps.forEach((step) => {
                if (step.attributeid) {
                  dispatch(actions.reset(`formData.${step.attributeid}`));
                }
              });
              dispatch(
                handleNextSteps(displayedSteps, stackactiontypes.PUSH, true)
              );
            }
            return;
          }

          // payload could just be { validated: false }
          if (payload.validated === false) {
            const { saveMap } = params.processDefinition;
            for (const stateParam of saveMap) {
              if (stateParam) {
                dispatch(actions.change(stateParam.statename, undefined));
                dispatch(actions.resetValidity(stateParam.statename));
              }
            }
            dispatch(
              changeFormStepProps({
                targetProp: params.processDefinition.targetProperty,
                targetValue: '',
                targetStepId: params.processDefinition.targetStep,
              })
            );
            dispatch(
              actions.setValidating(`formData.${step.attributeid}`, false)
            );
          }

          const saveMapLength = params.processDefinition.saveMap.length;

          for (let i = 0; i < saveMapLength; i++) {
            let stateParam = params.processDefinition.saveMap[i];
            let key = stateParam.paramname;
            let value = payload[key];

            if (stateParam === undefined || value === undefined) continue;

            let statePath = stateParam.statename.split('.')[0];

            if (
              Object.entries(value).length === 1 &&
              params.processDefinition.targetProperty !== 'enumeration'
            )
              value = value[Object.keys(value)[0]];

            switch (statePath) {
              case 'formMeta':
                dispatch(
                  changeMeta(
                    objectMapper.setKeyValue({}, stateParam.statename, value)
                  )
                );
                break;
              case 'formDefinition':
                dispatch(
                  changeFormDefinition(
                    objectMapper.setKeyValue({}, stateParam.statename, value)
                      .formDefinition
                  )
                );
                break;
              case 'formState':
                value = transformFormStateVar(
                  stateParam.statename,
                  value,
                  params.processDefinition.displaymap
                );
                dispatch(
                  changeFormState(
                    objectMapper.setKeyValue({}, stateParam.statename, value)
                      .formState
                  )
                );
                break;
              default:
                dispatch(actions.change(stateParam.statename, value));
                dispatch(actions.resetValidity(stateParam.statename));
            }

            if (
              statePath == 'formState' &&
              stateParam.statename.includes('formStep')
            ) {
              let paths = stateParam.statename.split('.');
              let attributeId = paths[2];
              dispatch(
                changeFormStepProps({
                  targetProp: paths.slice(-1)[0],
                  targetValue: value,
                  targetStepId: attributeId,
                })
              );
            }
          }

          let stepChangeTarget = getState().formDefinition.stepSet.findIndex(
            (_step) =>
              _step.attributeid !== undefined &&
              _step.attributeid === params.processDefinition.targetStep
          );

          if (
            stepChangeTarget !== -1 &&
            params.processDefinition.targetProperty !== 'enumeration'
          ) {
            let shouldRun = true;
            let textOutput = params.processDefinition.actionafterchangetext;
            const isTokenized =
              params.processDefinition.actionafterchangetext &&
              params.processDefinition.actionafterchangetext.indexOf('{') !==
                -1;
            if (isTokenized) {
              let tokens = textOutput.match(/{([a-zA-Z]*)\}/g);
              tokens.forEach((param) => {
                const stateVal =
                  getState().formData[param.replace(/[{}]/g, '')];
                textOutput = textOutput.replace(
                  param,
                  formatter ? formatter(stateVal) : stateVal
                );
              });
              if (
                textOutput.indexOf('undefined') !== -1 ||
                textOutput.indexOf('{') !== -1
              )
                shouldRun = false;
            }
            //evaluate if property should change based on condition
            if (params.processDefinition.condition) {
              let script = params.processDefinition.condition;
              let tokens = script.match(/{([a-zA-Z.]*)\}/g);
              tokens.forEach((param) => {
                const state = getState();
                const kv = objectMapper.getKeyValue(
                  state,
                  param.replace(/[{}]/g, '')
                );

                script = script.replace(param, kv == null ? '' : kv);
              });
              shouldRun = eval(script);
            }
            if (shouldRun) {
              dispatch(
                changeFormStepProps({
                  targetProp: params.processDefinition.targetProperty,
                  targetValue: textOutput,
                  targetStepId: params.processDefinition.targetStep,
                })
              );
            }
          }
          dispatch(
            actions.setValidating(`formData.${step.attributeid}`, false)
          );
        })
        .catch((error) => {
          ltLogger(logtypes.ERROR, undefined, {}, undefined, {
            error: error,
          });
          //reset properties
          dispatch(
            changeFormStepProps({
              targetProp: params.processDefinition.targetProperty,
              targetValue: '',
              targetStepId: params.processDefinition.targetStep,
            })
          );
          dispatch(
            actions.setValidating(`formData.${step.attributeid}`, false)
          );
          throw error;
        });
    };

    let actionArray = [];
    step.actionsafterchange.forEach((action) => {
      actionArray.push(() =>
        processActionsAfterStep({
          processDefinition: {
            ...action,
          },
          ...{
            formData: getState().formData,
            formMeta: getState().formMeta,
            formDefinition: getState().formDefinition,
          },
        })
      );
    });

    return actionArray.length > 1
      ? actionArray.reduce(function (prevTaskPromise, task) {
          return prevTaskPromise().then(task);
        })
      : actionArray[0]();
  };
}

export function updateMultipleAttributes(tokenizedText, valueString) {
  return function (dispatch) {
    const re = /{([a-zA-Z\d]+)\}/g;
    const attributes = tokenizedText.match(re);
    const values = valueString.split(',');
    let i = 0;
    attributes.forEach((item) => {
      if (values[i]) {
        dispatch(
          actions.change(
            `formData.${item.replace(/\{|\}/gi, '')}`,
            values[i].trim()
          )
        );
        i++;
      }
    });
  };
}

export function updateMultipleAttributesMap(selectmap, item) {
  return function (dispatch) {
    let map = objectMapper(item, JSON.parse(selectmap));

    Object.entries(map).forEach(([key, value]) => {
      switch (key) {
        case 'formMeta':
          dispatch(changeMeta(objectMapper.setKeyValue({}, key, value)));
          break;
        case 'formDefinition':
          dispatch(
            changeFormDefinition(
              objectMapper.setKeyValue({}, key, value).formDefinition
            )
          );
          break;
        case 'formState':
          Object.entries(map[key]).forEach(([key, value]) => {
            dispatch(changeFormState(objectMapper.setKeyValue({}, key, value)));
          });
          break;
        case 'formData':
          Object.entries(map[key]).forEach(([key, value]) => {
            dispatch(actions.change(`formData.${key}`, value));
          });
          break;
      }
    });
  };
}

export function resetHiddenComponent(attributeId) {
  return function (dispatch) {
    dispatch(actions.change(`formData.${attributeId}`, undefined));
    dispatch(actions.resetValidity(`formData.${attributeId}`));
    dispatch(actions.setUntouched(`formData.${attributeId}`));
  };
}

export function resetValidityAndFormDataFields(attributeId, stepId) {
  return function (dispatch, getState) {
    if (stepId === 'RadiusInMiles' && attributeId === 'VehicleDealerList') {
      dispatch(actions.change(`formData.${attributeId}`, undefined));
      dispatch(actions.resetValidity(`formData.${attributeId}`));
      dispatch(actions.setUntouched(`formData.${attributeId}`));
      dispatch(actions.setPristine(`formData.${attributeId}`));
      if (getState().formData.extraDataFields) {
        Object.keys(getState().formData.extraDataFields).forEach(
          (extraprop) => {
            if (
              Object.keys(getState().formData).find(
                (item) => item === extraprop
              )
            ) {
              dispatch(actions.reset(`formData.${extraprop}`));
              return;
            }
          }
        );
      }
    }
  };
}

function transformFormStateVar(path, value, displaymap) {
  let target = path.split('.').slice(-1)[0];

  switch (target) {
    case 'enumeration':
      return value.map((item) => {
        if (displaymap) {
          return objectMapper(item, JSON.parse(displaymap));
        } else if (typeof item === 'string') {
          return { label: item, value: item };
        } else {
          return { label: item.label, value: item.value };
        }
      });
    default:
      return value;
  }
}

function submittingForm(loadingMessage) {
  return { type: types.FORM_SUBMITTING, loadingMessage };
}

function updatePreviousQuestionMeta(currentSteps, cleanFormData) {
  return function (dispatch) {
    let previousquestionsasked = [];
    let previousquestionsanswered = [];
    currentSteps.forEach((step) => {
      if (
        step &&
        step.attributeid &&
        cleanFormData &&
        cleanFormData[step.attributeid]
      ) {
        previousquestionsasked.push(step.attributeid);
        previousquestionsanswered.push(
          isPII(step.attributeid)
            ? 'pii-field'
            : cleanFormData[step.attributeid]
        );
      }
    });

    if (previousquestionsasked.length > 0) {
      dispatch(
        changeMeta({
          formMeta: {
            previousquestionasked: previousquestionsasked.join('|'),
            previousquestionsanswer: previousquestionsanswered.join('|'),
          },
        })
      );
    }
  };
}

function updateCurrentQuestionFormMeta(
  currentSteps,
  cleanFormData,
  stackAction,
  variation,
  cleanMeta
) {
  let currentQuestionAsked = [];
  let currentQuestionAnswered = [];
  currentSteps.forEach((step) => {
    if (
      step &&
      step.attributeid &&
      cleanFormData &&
      cleanFormData[step.attributeid]
    ) {
      currentQuestionAsked.push(step.attributeid);
      currentQuestionAnswered.push(
        isPII(step.attributeid) ? 'pii-field' : cleanFormData[step.attributeid]
      );
    }
  });
  return {
    formMeta: {
      stepSet: currentSteps[0].id,
      vid: variation,
      clientTimeStamp: Date.now().toString(),
      currentquestionasked: currentQuestionAsked.join('|'),
      currentquestionanswered: currentQuestionAnswered.join('|'),
      ...cleanMeta,
    },
    formData: stackAction === stackactiontypes.PUSH ? { ...cleanFormData } : {},
  };
}

export function restrictJobStartDate(id, enumerations) {
  let maxYear = new Date().getFullYear();
  let { formData } = window.ReduxStore.getState();
  let minYear = new Date(formData.DOB).getFullYear();
  if (id === 'JobStartDate' && minYear) {
    return enumerations.filter((ele) => {
      if (ele.value <= maxYear && ele.value > minYear) {
        return ele;
      }
    });
  }
  return enumerations;
}
