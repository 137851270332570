/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import * as formatTypes from '../../utils/formatTypes';
import { getStore } from '../../store';

const AddOn = ({ item }) => {
  const { type, value } = item;
  // Add-on styles currently require both "ltAddOnItem" and
  // "fa" classes to be styled correctly. However, if this
  // add-on "type" is anything except "dynamic", it will be
  // included as part of the value of "type", so we only
  // need to include it in "dynamic" types.
  const classNames = ['ltAddOnItem', item.position];
  let content = null;

  if (type === 'dynamic') {
    const { formData } = getStore().getState();
    const fn = `"use strict"; return (${value});`;
    const callback = Function(fn)();
    classNames.push('fa');

    if (typeof callback === 'function') {
      content = callback(formData);
    }
  } else {
    classNames.push(type);
  }

  return <span className={classNames.join(' ')}>{content}</span>;
};

AddOn.propTypes = {
  item: PropTypes.object,
  formData: PropTypes.object,
};

function WithAddOns(Component) {
  function WithAddOnComponent({ addons = {}, ...props }) {
    const { enabled, items } = addons;
    const [type, setType] = React.useState(props.type);
    const togglePassword = () => {
      if (type === 'password') {
        setType('text');
        return;
      }
      setType('password');
    };
    if (enabled) {
      const leftAddOn = items.find((p) => p.position === 'left');
      const rightAddOn = items.find((p) => p.position === 'right');
      return (
        <div className="ltInputAddOnContainer">
          {leftAddOn && <AddOn item={leftAddOn} />}
          <Component {...props} />
          {rightAddOn && <AddOn item={rightAddOn} />}
        </div>
      );
    } else if (
      props.type === formatTypes.PASSWORD &&
      props.isPasswordViewEnabled === true
    ) {
      return (
        <div className="ltInputPasswordIconContainer">
          <Component {...props} type={type} />
          <span onClick={togglePassword}>
            {type === 'password' ? (
              <i className={`ltFormControlIcon Password eye-slash`} />
            ) : (
              <i className={`ltFormControlIcon Password eye`} />
            )}
          </span>
        </div>
      );
    }
    return <Component {...props} />;
  }

  WithAddOnComponent.propTypes = {
    addons: PropTypes.object,
    type: PropTypes.string,
    id: PropTypes.string,
    isPasswordViewEnabled: PropTypes.bool,
  };

  return WithAddOnComponent;
}

export default WithAddOns;
