import React from 'react';
import PropTypes from 'prop-types';
import {
  restrictJobStartDate,
  changeFormStepProps,
} from '../../../actions/formActions';
import { getStore } from '../../../store/index';
import { getAsyncValidations } from '../../../validators/validators';
import { actions } from 'react-redux-form';
import { reportFieldEngagement } from '../../../bootstrap';
import * as formatTypes from '../../../utils/formatTypes';

class MultiPartControl extends React.Component {
  constructor(props) {
    super(props);
    // PHX-5370 :: TODO
    // This logic is updating the year for a JobStartDate based on the entered DOB
    // Refactor to use state to display the proper year options like Day was updated in PHX-5370.
    props.controlDefs['box3'].enumeration
      ? getStore().dispatch(
          changeFormStepProps({
            targetProp: 'multipartcontrol.box3.enumeration',
            targetValue: restrictJobStartDate(
              props.uniqueId,
              props.controlDefs['box3'].enumeration
            ),
            targetStepId: props.uniqueId,
          })
        )
      : getStore().dispatch(
          changeFormStepProps({
            targetProp: 'multipartcontrol.box3.enumeration',
            targetValue: [],
            targetStepId: props.uniqueId,
          })
        );
    if (props.value !== '') {
      this.state = props.splitFunction(props.value);
    } else {
      this.state = {
        box1value: '',
        box2value: props.controlDefs['box2'].value
          ? props.controlDefs['box2'].value
          : '',
        box3value: '',
      };
    }

    if (this.props.uniqueId === 'Last4SSN') {
      this.state = {
        box1value:
          props.controlDefs['box1'].placeholder.trim().length > 0
            ? props.controlDefs['box1'].placeholder.trim()
            : 'XXX',
        box2value:
          props.controlDefs['box2'].placeholder.trim().length > 0
            ? props.controlDefs['box2'].placeholder.trim()
            : 'XX',
      };
    }
    this.state = this.state ?? {};

    // If the multipart has any type of date, we want to know what box has the month, day, and year
    // and if the multipart has two or three inputs
    // and which box has the month, day, and year
    const isDateMultiPartControl =
      props.controlDefs.stepFormat === formatTypes.DOB ||
      props.controlDefs.stepFormat === formatTypes.DATE;

    // PHX-5370
    // Setting this as a default up here will let us avoid needing two "elses" to set the processedOptions in the two
    // cases where the new logic isn't relevant (not date multipart OR days isn't a dropdown)
    this.state.requiresUpdatedDays = false;
    if (isDateMultiPartControl) {
      //PHX-5370: The Default applies to Generics as they currently are implemented without the arrangement for Dates
      const defaultDateArrangement = ['M', 'D', 'Y'];
      const incomingArrangement = props.controlDefs.arrangement;
      const arrangementIsValid = () => {
        // Arrangement is valid if it exists...
        if (incomingArrangement) {
          // and it meets the proper length...
          if (incomingArrangement.length === defaultDateArrangement.length) {
            // and it contains all the expected characters
            return defaultDateArrangement.every((char) =>
              incomingArrangement.includes(char)
            );
          }
        }
      };
      const arrangementArray = arrangementIsValid()
        ? Array.from(incomingArrangement)
        : defaultDateArrangement;

      const boxWithDay = `box${arrangementArray.indexOf('D') + 1}`;
      if (props.controlDefs[boxWithDay].type === 'select') {
        const boxWithMonth = `box${arrangementArray.indexOf('M') + 1}`;
        const boxWithYear = `box${arrangementArray.indexOf('Y') + 1}`;
        this.state.boxWithDay = boxWithDay;
        this.state.boxWithMonth = boxWithMonth;
        this.state.boxWithYear = boxWithYear;
        this.state.requiresUpdatedDays = true;
        this.state.monthsWithThirty = ['04', '06', '09', '11'];
        this.state.februaryNumeric = '02';
        this.state.isLeapYear = this.determineLeapYear(
          this.state[`${boxWithYear}value`]
        );
        const boxState = {
          box1: this.state.box1value,
          box2: this.state.box2value,
          box3: this.state.box3value,
        };
        this.state[boxWithDay] = {
          processedOptions: this.generateDateOptions(
            boxState,
            this.state.isLeapYear
          ),
        };
        this.state[boxWithMonth] = {
          processedOptions: props.controlDefs[boxWithMonth].enumeration,
        };
        this.state[boxWithYear] = {
          processedOptions: props.controlDefs[boxWithYear].enumeration,
        };
      }
    }

    if (!this.state.requiresUpdatedDays) {
      // PHX-5370: The processedOptions below may not be used if the related box is a textbox instead of dropdown
      this.state = {
        ...this.state,
        box1: {
          processedOptions: props.controlDefs['box1'].enumeration,
        },
        box2: {
          processedOptions: props.controlDefs['box2'].enumeration,
        },
        box3: {
          processedOptions: props.controlDefs['box3'].enumeration,
        },
      };
    }

    this.handleChange = this.handleChange.bind(this);
    this.onKeyDown = this.handleKeyDown.bind(this);
    this.focus = this.focus.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value)
      this.setState(this.props.splitFunction(nextProps.value));
  }

  focus(target, direction) {
    const index =
      direction === 'forward'
        ? parseInt(target.substr(-1)) + 1
        : parseInt(target.substr(-1)) - 1;
    if (this[`box${index}`] !== undefined) {
      this.props.controlDefs.box1.type === 'select' &&
      this.props.controlDefs.box2.type === 'select'
        ? this[`box${index}`].click()
        : this[`box${index}`].focus();
    }
  }

  getBoxStateValue(isValid, event) {
    return isValid
      ? event.currentTarget.value
      : event.currentTarget.value === ''
      ? event.currentTarget.value
      : event.currentTarget.defaultValue;
  }

  handleKeyDown(event, target) {
    // let key = event.keyCode || event.charCode;
    let key = event.which;
    let textField = this[target];
    const selectedText = textField.value.substring(
      textField.selectionStart,
      textField.selectionEnd
    );
    // Backspace or Delete
    if (key === 8 || key === 46) {
      if (this[target].value === '' || this[target].value.length === 0) {
        this.focus(target, 'backward');
        event.preventDefault();
      }
      // Tab
    } else if (key === 9) {
      return;
    } else {
      if (
        this.props.jumpRules !== undefined &&
        this[target].value.length === this.props.jumpRules[target] &&
        selectedText.length === 0
      ) {
        this.focus(target, 'forward');
        key !== 13 && event.preventDefault();
      }
    }
  }

  determineLeapYear(incomingYear) {
    return (
      incomingYear != '' &&
      ((incomingYear % 4 == 0 && incomingYear % 100 != 0) ||
        incomingYear % 400 == 0)
    );
  }

  generateDateOptions(boxState, isLeapYear) {
    const daysInMonth =
      boxState[this.state.boxWithMonth] === this.state.februaryNumeric &&
      isLeapYear
        ? 29
        : boxState[this.state.boxWithMonth] === this.state.februaryNumeric
        ? 28
        : this.state.monthsWithThirty.includes(
            boxState[this.state.boxWithMonth]
          )
        ? 30
        : this.props.controlDefs[this.state.boxWithDay].enumeration.length;
    return this.props.controlDefs[this.state.boxWithDay].enumeration.slice(
      0,
      daysInMonth
    );
  }

  handleChange(event, target) {
    let regex = /^[0-9\b]+$/;
    let isValid = regex.test(event.currentTarget.value);

    const boxState = (() => {
      switch (target) {
        case 'box1':
          return {
            box1: this.getBoxStateValue(isValid, event),
            box2: this.state.box2value,
            box3: this.state.box3value,
          };
        case 'box2':
          return {
            box1: this.state.box1value,
            box2: this.getBoxStateValue(isValid, event),
            box3: this.state.box3value,
          };
        case 'box3':
          return {
            box1: this.state.box1value,
            box2: this.state.box2value,
            box3: this.getBoxStateValue(isValid, event),
          };
        default:
          return {};
      }
    })();

    const outVal = this.props.handleValueCombine(boxState);
    const isPopulated = (val) =>
      val !== undefined && val !== null && val !== '';
    let allPopulated =
      isPopulated(boxState.box1) &&
      isPopulated(boxState.box2) &&
      isPopulated(boxState.box3);
    if (this.props.uniqueId === 'SSN' && allPopulated) {
      allPopulated =
        boxState.box1.length == 3 &&
        boxState.box2.length === 2 &&
        boxState.box3.length === 4;
    }
    if (allPopulated) {
      reportFieldEngagement(this.props.uniqueId, outVal);
    }
    let asyncValidations = getAsyncValidations(this.props.validation);
    let asyncValidationFields = [];

    if (asyncValidations) {
      for (let i = 0; i < asyncValidations.length; i++) {
        if (asyncValidations[i].validationtype) {
          asyncValidationFields.push(asyncValidations[i].validationtype);
        }
      }
      getStore().dispatch(
        actions.resetValidity(
          `formData.${this.props.uniqueId}`,
          asyncValidationFields
        )
      );
    }

    this.setState({
      [`${target}value`]: this.getBoxStateValue(isValid, event),
      value: outVal,
    });

    // If the control is any type of date where updates are required,
    // calculate if the selected Year is a Leap Year
    // And then generate date options for the dropdown
    if (this.state.requiresUpdatedDays) {
      let isLeapYear =
        target === this.state.boxWithYear
          ? this.determineLeapYear(boxState[target])
          : this.state.isLeapYear;
      this.setState({
        isLeapYear: isLeapYear,
      });
      this.setState({
        [`${this.state.boxWithDay}`]: {
          processedOptions: this.generateDateOptions(boxState, isLeapYear),
        },
      });
    }

    this.props.onupdate(outVal);

    if (this.props.jumpRules !== undefined) {
      if (boxState[target].length === this.props.jumpRules[target]) {
        this.focus(target, 'forward');
      }
    }

    /**
     * Temporary functionality to auto-forward users on
     * completion of the step, if the multipart is NOT Phone
     * or SSN.
     */
    if (
      boxState.box1 &&
      boxState.box2 &&
      boxState.box3 &&
      this.props.uniqueId !== 'PhoneNumber' &&
      this.props.uniqueId !== 'SSN' &&
      this.props.uniqueId !== 'Last4SSN' &&
      !this.props.disableAutoforward
    )
      this.props.submitFunc();
  }

  render() {
    const {
      uniqueId,
      controlDefs,
      inputClass,
      wrapperClass,
      placeholders,
      value,
      jumpRules,
      autoFocus,
      mask,
    } = this.props;
    const maskTextClass = mask ? 'mask-input' : '';
    const inputClassName =
      this.props.uniqueId === 'Last4SSN' ? 'ltFormControlMulti' : inputClass;
    const wrapperClassName =
      this.props.uniqueId === 'Last4SSN'
        ? 'ltFormControlMultiWrapper'
        : wrapperClass;
    const isDisabled = this.props.uniqueId === 'Last4SSN' ? true : false;
    const contentMultipartUniqueIdClass =
      this.props.uniqueId === 'Last4SSN' || this.props.uniqueId === 'SSN'
        ? 'SSN'
        : '';
    const contentMultipartInputTypeClass =
      this.props.uniqueId === 'Last4SSN' || this.props.uniqueId === 'SSN'
        ? controlDefs['box1'].type === 'text'
          ? 'text'
          : 'select'
        : '';
    return (
      <div
        className={`ltFormGroupContentMultipart ${contentMultipartUniqueIdClass} ${contentMultipartInputTypeClass}`}
      >
        <input
          id="hidden"
          placeholder="hidden one"
          style={{ display: 'none' }}
          type={uniqueId === 'SSN' || 'PhoneNumber' ? 'tel' : 'text'}
          value={value}
          readOnly={true}
        />
        <span className={`${wrapperClassName} ${uniqueId} box1`}>
          {controlDefs['box1'].type === 'text' ? (
            <input
              disabled={isDisabled}
              id={`${uniqueId}.box1`}
              name={`${uniqueId}.box1`}
              value={this.state.box1value}
              type={uniqueId === 'SSN' || 'PhoneNumber' ? 'tel' : 'number'}
              autoFocus={window.innerWidth >= 768 && autoFocus}
              maxLength={jumpRules.box1}
              className={`${inputClassName} ${uniqueId} box1 ${maskTextClass}`}
              onChange={(e) => this.handleChange(e, 'box1')}
              onKeyDown={(e) => this.handleKeyDown(e, 'box1')}
              style={controlDefs['box1'].style}
              autoComplete="off"
              placeholder={placeholders['box1Placeholder']}
              ref={(input) => {
                this.box1 = input;
              }}
            />
          ) : (
            <select
              autoFocus={autoFocus}
              className={`${inputClass} ${uniqueId} box1`}
              onChange={(e) => this.handleChange(e, 'box1')}
              ref={(input) => {
                this.box1 = input;
              }}
              value={this.state.box1value}
            >
              <option value="">{placeholders['box1Placeholder']}</option>
              {this.state['box1'].processedOptions.map((choice) => (
                <option key={choice.value} value={choice.value}>
                  {choice.label}
                </option>
              ))}
            </select>
          )}
        </span>
        <span
          className={`${wrapperClassName} ${uniqueId} box2`}
          style={controlDefs['box2'].style}
        >
          {controlDefs['box2'].type === 'text' ? (
            <input
              disabled={isDisabled}
              id={`${uniqueId}.box2`}
              name={`${uniqueId}.box2`}
              value={this.state.box2value}
              type={uniqueId === 'SSN' || 'PhoneNumber' ? 'tel' : 'number'}
              className={`${inputClassName} ${uniqueId} box2 ${maskTextClass}`}
              onChange={(e) => this.handleChange(e, 'box2')}
              onKeyDown={(e) => this.handleKeyDown(e, 'box2')}
              autoComplete="off"
              maxLength={jumpRules.box2}
              placeholder={placeholders['box2Placeholder']}
              ref={(input) => {
                this.box2 = input;
              }}
            />
          ) : (
            <select
              className={`${inputClass} ${uniqueId} box2`}
              onChange={(e) => this.handleChange(e, 'box2')}
              ref={(input) => {
                this.box2 = input;
              }}
              value={this.state.box2value}
            >
              <option value="">{placeholders['box2Placeholder']}</option>
              {this.state['box2'].processedOptions.map((choice) => (
                <option key={choice.value} value={choice.value}>
                  {choice.label}
                </option>
              ))}
            </select>
          )}
        </span>
        <span className={`${wrapperClass} ${uniqueId} box3`}>
          {controlDefs['box3'].type === 'text' ? (
            <input
              id={`${uniqueId}.box3`}
              name={`${uniqueId}.box3`}
              value={this.state.box3value}
              onChange={(e) => this.handleChange(e, 'box3')}
              onKeyDown={(e) => this.handleKeyDown(e, 'box3')}
              maxLength={jumpRules.box3}
              style={controlDefs['box3'].style}
              type={uniqueId === 'SSN' || 'PhoneNumber' ? 'tel' : 'number'}
              className={`${inputClass} ${uniqueId} box3 ${maskTextClass}`}
              autoComplete="off"
              placeholder={placeholders['box3Placeholder']}
              ref={(input) => {
                this.box3 = input;
              }}
            />
          ) : (
            <select
              className={`${inputClass} ${uniqueId} box3`}
              onChange={(e) => this.handleChange(e, 'box3')}
              ref={(input) => {
                this.box3 = input;
              }}
              value={this.state.box3value}
            >
              <option value="">{placeholders['box3Placeholder']}</option>
              {this.state['box3'].processedOptions.map((choice) => (
                <option key={choice.value} value={choice.value}>
                  {choice.label}
                </option>
              ))}
            </select>
          )}
        </span>
      </div>
    );
  }
}

MultiPartControl.defaultProps = {
  concatChar: '-',
};

MultiPartControl.propTypes = {
  type: PropTypes.string,
  onupdate: PropTypes.func,
  step: PropTypes.object,
  concatChar: PropTypes.string,
  value: PropTypes.string,
  splitFunction: PropTypes.func,
  controlDefs: PropTypes.object,
  handleValueCombine: PropTypes.func,
  jumpRules: PropTypes.object,
  submitFunc: PropTypes.func,
  uniqueId: PropTypes.string,
  inputClass: PropTypes.string,
  wrapperClass: PropTypes.string,
  placeholders: PropTypes.object,
  autoFocus: PropTypes.bool,
  mask: PropTypes.bool,
  disableAutoforward: PropTypes.bool,
  validation: PropTypes.object,
};

export default MultiPartControl;
